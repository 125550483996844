// @flow
import { all, fork, put, takeLatest, call } from 'redux-saga/effects';

import {
    getAllAnalyticsSurcharges as getAllAnalyticsSurchargesApi,
} from '../../helpers/';

import { analyticsSurchargesApiResponseSuccess, analyticsSurchargesApiResponseError } from './actions';
import { AnalyticsSurchargesActionTypes } from './constants';
import { analyticsSurchargesResponseToAnalyticsSurchargeObject } from '../../helpers/mappers/analyticsSurcharges';
import { addToast } from '../actions';
import { paginationHeadersResponse } from '../../helpers/mappers/pagination';

function* getAllAnalyticsSurcharges({ payload: { query } }) {
    try {
        const getAllAnalyticsSurchargesResponse = yield call(getAllAnalyticsSurchargesApi, query);
        const analyticSurcharges = getAllAnalyticsSurchargesResponse.data.map((analyticsSurchargesResponse) => {
            return analyticsSurchargesResponseToAnalyticsSurchargeObject(analyticsSurchargesResponse);
        });
        const pagination = { ...paginationHeadersResponse(getAllAnalyticsSurchargesResponse.headers), ...query };
        yield put(analyticsSurchargesApiResponseSuccess(AnalyticsSurchargesActionTypes.GET_ALL_ANALYTICS_SURCHARGES, { analyticSurcharges, pagination }));
    } catch (error) {
        yield put(addToast({ desc: error.errors[0].message, type: 'error' }));
        yield put(analyticsSurchargesApiResponseError(AnalyticsSurchargesActionTypes.GET_ALL_ANALYTICS_SURCHARGES, error));
    }
}

export function* watchGetAllAnalyticsSurcharges(): any {
    yield takeLatest(AnalyticsSurchargesActionTypes.GET_ALL_ANALYTICS_SURCHARGES, getAllAnalyticsSurcharges);
}

function* analyticsSurchargesSaga(): any {
    yield all([fork(watchGetAllAnalyticsSurcharges)]);
}

export default analyticsSurchargesSaga;
