const config = {
    API_BASE_URL: readFromEnv('API_BASE_URL'),
    LABELS_URL: readFromEnv('LABELS_URL'),
    USERS_URL: readFromEnv('USERS_URL'),
    PAYMENT_METHODS_URL: readFromEnv('PAYMENT_METHODS_URL'),
    CARRIER_URL: readFromEnv('CARRIER_URL'),
    ORDERS_URL: readFromEnv('ORDERS_URL'),
    AUTOMATION_URL: readFromEnv('AUTOMATION_URL'),
    BATCHES_URL: readFromEnv('BATCHES_URL'),
    PRODUCTS_URL: readFromEnv('PRODUCTS_URL'),
    ORDER_ANALYTICS_URL: readFromEnv('ORDER_ANALYTICS_URL'),
    SHIPMENTS_ANALYTICS_URL: readFromEnv('SHIPMENTS_ANALYTICS_URL'),
    ZONES_ANALYTICS_URL: readFromEnv('ZONES_ANALYTICS_URL'),
    INVOICE_ANALYTICS_URL: readFromEnv('INVOICE_ANALYTICS_URL'),
    RATE_SETS_URL: readFromEnv('RATE_SETS_URL'),
    MANIFESTS_URL: readFromEnv('MANIFESTS_URL'),
    PRICING_URL: readFromEnv('PRICING_URL'),
    INTEGRATIONS_URL: readFromEnv('INTEGRATIONS_URL'),
    SHIPMENTS_URL: readFromEnv('SHIPMENTS_URL'),
    ADDRESSBOOK_URL: readFromEnv('ADDRESSBOOK_URL'),
    VERIFY_ADDRESS_URL: readFromEnv('VERIFY_ADDRESS_URL'),
    SECURE_LOCAL_STORAGE_HASH_KEY: readFromEnv('SECURE_LOCAL_STORAGE_HASH_KEY'),
    IMPORT_BATCHES_URL: readFromEnv('IMPORT_BATCHES_URL'),
    ANALYTICS_RECORDS_URL: readFromEnv('ANALYTICS_RECORDS_URL'),
    ANALYTICS_RATES_URL: readFromEnv('ANALYTICS_RATES_URL'),
    ANALYTICS_SURCHARGES_URL: readFromEnv('ANALYTICS_SURCHARGES_URL'),
    STRIPE_PUBLISHABLE_KEY: readFromEnv('STRIPE_PUBLISHABLE_KEY'),
    ENVIRONMENT: readFromEnv('ENVIRONMENT'),
};

export default config;

///////////////

export function readFromEnv(key) {
    return window[key] === '__' + key + '__' ? process.env['REACT_APP_' + key] : window[key];
}
