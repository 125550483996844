const analyticsSurchargesResponseToAnalyticsSurchargeObject = (response) => {
    return {
        id: response.id,
        lineNumber: response.line_number,
        trackingNumber: response.tracking_number,
        externalOrderId: response.external_order_id,
        surchargeValue: response.surcharge_value,
        surchargeType: response.surcharge_type,
    };
};

export { analyticsSurchargesResponseToAnalyticsSurchargeObject };