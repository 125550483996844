import { AnalyticsSurchargesActionTypes } from './constants';

type AnalyticsSurchargesAction = { type: string, payload: {} | string };

export const analyticsSurchargesApiResponseSuccess = (actionType: string, data: any): AnalyticsSurchargesAction => ({
    type: AnalyticsSurchargesActionTypes.API_RESPONSE_SUCCESS,
    payload: { actionType, data },
});

export const analyticsSurchargesApiResponseError = (actionType: string, error: string): AnalyticsSurchargesAction => ({
    type: AnalyticsSurchargesActionTypes.API_RESPONSE_ERROR,
    payload: { actionType, error },
});

export const getAllAnalyticsSurcharges = (query): AnalyticsSurchargesAction => ({
    type: AnalyticsSurchargesActionTypes.GET_ALL_ANALYTICS_SURCHARGES,
    payload: { query },
});

export const analyticsSurchargesResetState = (): AnalyticsSurchargesAction => ({
    type: AnalyticsSurchargesActionTypes.RESET_STATE,
    payload: {},
});

export const analyticsSurchargesResetAllErrors = (): AnalyticsSurchargesAction => ({
    type: AnalyticsSurchargesActionTypes.RESET_ALL_ERRORS,
    payload: {},
});