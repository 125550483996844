// @flow
import { combineReducers } from 'redux';

import Auth from './auth/reducers';
import Layout from './layout/reducers';
import Orders from './orders/reducers';
import Shipments from './shipments/reducers';
import Manifests from './manifests/reducers';
import Integrations from './integrations/reducers';
import EditOrder from './edit-order/reducers';
import ViewOrder from './view-order/reducers';
import NewOrder from './new-order/reducers';
import AddressBook from './addressbook/reducers';
import Manifest from './manifest/reducers';
import Profile from './profile2/reducers';
import LocalStorage from './local-storage/reducers';
import PackageItems from './package-items/reducers';
import Toast from './toast/reducers';
import LabelAndDocsSettings from './labels-docs-settings/reducers';
import Package from './package/reducers';
import PackageSettings from './package-settings/reducers';
import BillingModal from './billing-modal/reducers';
import Billing from './billing/reducers';
import AnalyticsRecords from './analytics-records/reducers';
import AnalyticsRates from './analytics-rates/reducers';
import AnalyticsSurcharges from './analytics-surcharges/reducers';
import IntegrationErrors from './integration-errors/reducers';
import Carrier from './carrier/reducers';
import Automation from './automation/reducers';
import Invitation from './invitation/reducers';
import Batches from './batches/reducers';
import OrderBatchErrors from './order-batch-errors/reducers';
import Products from './products/reducers';
import Shopify from './shopify/reducers';
import OrderAnalytics from './order-analytics/reducers';
import ShipmentAnalytics from './shipment-analytics/reducers';
import ZoneAnalytics from './zone-analytics/reducers';
import RateSets from './rate-sets/reducers';
import InvoiceAnalytics from './invoice-analytics/reducers';
import AnalyticsRatingErrors from './analytics-rating-errors/reducers';

export default (combineReducers({
    Auth,
    Layout,
    Orders,
    Shipments,
    Manifests,
    Integrations,
    EditOrder,
    ViewOrder,
    NewOrder,
    AddressBook,
    Manifest,
    Profile,
    LocalStorage,
    PackageItems,
    Toast,
    LabelAndDocsSettings,
    Package,
    PackageSettings,
    BillingModal,
    Billing,
    AnalyticsRecords,
    AnalyticsRates,
    AnalyticsSurcharges,
    IntegrationErrors,
    Carrier,
    Automation,
    Invitation,
    Batches,
    OrderBatchErrors,
    Products,
    Shopify,
    OrderAnalytics,
    ShipmentAnalytics,
    ZoneAnalytics,
    RateSets,
    InvoiceAnalytics,
    AnalyticsRatingErrors,
}): any);
