import { InvoiceAnalyticsActionTypes } from './constants';

type InvoiceAnalyticsAction = { type: string, payload: {} | string };

export const invoiceAnalyticsApiResponseSuccess = (actionType: string, data: any): InvoiceAnalyticsAction => ({
    type: InvoiceAnalyticsActionTypes.API_RESPONSE_SUCCESS,
    payload: { actionType, data },
});

export const invoiceAnalyticsApiResponseError = (actionType: string, error: string): InvoiceAnalyticsAction => ({
    type: InvoiceAnalyticsActionTypes.API_RESPONSE_ERROR,
    payload: { actionType, error },
});

export const invoiceAnalyticsGetNoShipments = (query: string): InvoiceAnalyticsAction => ({
    type: InvoiceAnalyticsActionTypes.GET_NO_SHIPMENTS_REQUEST,
    payload: { query },
});

export const invoiceAnalyticsGetTopZone = (query: string): InvoiceAnalyticsAction => ({
    type: InvoiceAnalyticsActionTypes.GET_TOP_ZONE_REQUEST,
    payload: { query },
});

export const invoiceAnalyticsGetTopWeight = (query: string): InvoiceAnalyticsAction => ({
    type: InvoiceAnalyticsActionTypes.GET_TOP_WEIGHT_REQUEST,
    payload: { query },
});

export const invoiceAnalyticsGetCost = (query: string): InvoiceAnalyticsAction => ({
    type: InvoiceAnalyticsActionTypes.GET_COST_REQUEST,
    payload: { query },
});

export const invoiceAnalyticsGetCostPerShipment = (query: string): InvoiceAnalyticsAction => ({
    type: InvoiceAnalyticsActionTypes.GET_AVG_COST_REQUEST,
    payload: { query },
});

export const getComparisonAnalyticsRecords = (query): AnalyticsRecordsAction => ({
    type: InvoiceAnalyticsActionTypes.GET_COMPARISON_ANALYTICS_RECORDS,
    payload: { query },
});

export const getComparisonRatesTotalChargeAmount = (query): AnalyticsRecordsAction => ({
    type: InvoiceAnalyticsActionTypes.GET_COMPARISON_RATES_TOTAL_CHARGE_AMOUNT,
    payload: { query },
});

export const getComparisonRatesAvgTotalChargeAmount = (query): AnalyticsRecordsAction => ({
    type: InvoiceAnalyticsActionTypes.GET_COMPARISON_RATES_AVG_TOTAL_CHARGE_AMOUNT,
    payload: { query },
});

export const getTotalNoSurcharges = (query): AnalyticsRecordsAction => ({
    type: InvoiceAnalyticsActionTypes.GET_TOTAL_NO_SURCHARGES,
    payload: { query },
});

export const getTotalNoIntegrationSurcharges = (query): AnalyticsRecordsAction => ({
    type: InvoiceAnalyticsActionTypes.GET_TOTAL_NO_INTEGRATION_SURCHARGES,
    payload: { query },
});

export const getTotalSurchargesCost = (query): AnalyticsRecordsAction => ({
    type: InvoiceAnalyticsActionTypes.GET_TOTAL_SURCHARGES_COST,
    payload: { query },
});

export const getTotalIntegrationSurchargesCost = (query): AnalyticsRecordsAction => ({
    type: InvoiceAnalyticsActionTypes.GET_TOTAL_INTEGRATION_SURCHARGES_COST,
    payload: { query },
});

export const getSurchargeSummary = (query): AnalyticsRecordsAction => ({
    type: InvoiceAnalyticsActionTypes.GET_SURCHARGE_SUMMARY,
    payload: { query },
});

export const getIntegrationSurchargeSummary = (query): AnalyticsRecordsAction => ({
    type: InvoiceAnalyticsActionTypes.GET_INTEGRATION_SURCHARGE_SUMMARY,
    payload: { query },
});

export const getTotalCostByWeightAndZone = (query): AnalyticsRecordsAction => ({
    type: InvoiceAnalyticsActionTypes.GET_TOTAL_COST_BY_WEIGHT_AND_ZONE,
    payload: { query },
});

export const getCountByWeightAndZone = (query): AnalyticsRecordsAction => ({
    type: InvoiceAnalyticsActionTypes.GET_COUNT_BY_WEIGHT_AND_ZONE,
    payload: { query },
});