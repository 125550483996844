import { AnalyticsSurchargesActionTypes } from './constants';
import { PAGINATION_DEFAULT_VALUE } from '../../helpers/defaultValue';

const INIT_STATE = {
    loadingAnalyticsSurcharges: false,
    error: null,
    analyticsSurcharges: null,
    pagination: PAGINATION_DEFAULT_VALUE,
};

type AnalyticsSurchargesAction = {
    type: string,
    payload: { actionType?: string, data?: any, error?: string },
};

type State = {
    loadingAnalyticsSurcharges: Boolean,
    error?: null,
    analyticsSurcharges: any
};

const AnalyticsSurcharges = (state: State = INIT_STATE, action: AnalyticsSurchargesAction): any => {
    switch (action.type) {
        case AnalyticsSurchargesActionTypes.API_RESPONSE_SUCCESS:
            switch (action.payload.actionType) {
                case AnalyticsSurchargesActionTypes.GET_ALL_ANALYTICS_SURCHARGES: {
                    return {
                        ...state,
                        loadingAnalyticsSurcharges: false,
                        analyticsSurcharges: action.payload.data.analyticSurcharges,
                        pagination: { ...PAGINATION_DEFAULT_VALUE, ...action.payload.data.pagination },
                    };
                }
                default:
                    return { ...state, loadingAnalyticsSurcharges: false };
            }

        case AnalyticsSurchargesActionTypes.API_RESPONSE_ERROR:
            switch (action.payload.actionType) {
                case AnalyticsSurchargesActionTypes.GET_ALL_ANALYTICS_SURCHARGES: {
                    return { ...state, loadingAnalyticsSurcharges: false, error: action.payload.error };
                }
                default:
                    return { ...state, loadingAnalyticsSurcharges: false };
            }

        case AnalyticsSurchargesActionTypes.GET_ALL_ANALYTICS_SURCHARGES:
            return { ...state, loadingAnalyticsSurcharges: true, analyticsSurcharges: null };
        case AnalyticsSurchargesActionTypes.RESET_STATE:
            return INIT_STATE;
        case AnalyticsSurchargesActionTypes.RESET_ALL_ERRORS:
            return { ...state, error: null };
        default:
            return { ...state };
    }
};

export default AnalyticsSurcharges;
