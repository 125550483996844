import { all, fork, put, takeLatest, call, takeEvery } from 'redux-saga/effects';
import {
    getInvoiceAnalyticsNoShipments as getInvoiceAnalyticsNoShipmentsApi,
    getInvoiceAnalyticsTopZone as getInvoiceAnalyticsTopZoneApi,
    getInvoiceAnalyticsTopWeight as getInvoiceAnalyticsTopWeightApi,
    getInvoiceAnalyticsCost as getInvoiceAnalyticsCostApi,
    getInvoiceAnalyticsAverageCost as getInvoiceAnalyticsAverageCostApi,
    getComparisonAnalyticsRecords as getAllAnalyticsRecordsApi,
    getComparisonCost as getComparisonRatesTotalChargeAmountApi,
    getComparisonAvgCost as getComparisonRatesAvgTotalChargeAmountApi,
    getTotalNoSurcharges as getTotalNoSurchargesApi,
    getTotalNoIntegrationSurcharges as getTotalNoIntegrationSurchargesApi,
    getTotalSurchargesCost as getTotalSurchargesCostApi,
    getTotalIntegrationSurchargesCost as getTotalIntegrationSurchargesCostApi,
    getSurchargeSummary as getSurchargeSummaryApi,
    getIntegrationSurchargeSummary as getIntegrationSurchargeSummaryApi,
    getTotalCostByWeightAndZone as getTotalCostByWeightAndZoneApi,
    getCountByWeightAndZone as getCountByWeightAndZoneApi
} from '../../helpers';
import { invoiceAnalyticsApiResponseSuccess, invoiceAnalyticsApiResponseError, } from './actions';
import { InvoiceAnalyticsActionTypes } from './constants';
import { analyticsRecordResponseToAnalyticsRecordObject } from '../../helpers/mappers/analyticsRecord';
import { surchargeSummaryResponseToSurchargeSummaryObject } from '../../helpers/mappers/surchargeSummary';
import { integrationSurchargeSummaryResponseToIntegrationSurchargeSummaryObject } from '../../helpers/mappers/integrationSurchargeSummary';
import { totalCostByWeightAndZoneResponseToTotalCostByWeightAndZoneObject } from '../../helpers/mappers/totalCostByWeightAndZone';
import { totalCountByWeightAndZoneResponseToTotalCountByWeightAndZoneObject } from '../../helpers/mappers/totalCountByWeightAndZone';
import { addToast } from '../actions';
import { paginationHeadersResponse } from '../../helpers/mappers/pagination';

export function* getInvoiceAnalyticsNoShipments({ payload: { query } }) {
    try {
        console.log('query', query);
        const response = yield call(getInvoiceAnalyticsNoShipmentsApi, query);
        yield put(invoiceAnalyticsApiResponseSuccess(InvoiceAnalyticsActionTypes.GET_NO_SHIPMENTS_REQUEST, response.data));
    } catch (error) {
        yield put(invoiceAnalyticsApiResponseError(InvoiceAnalyticsActionTypes.GET_NO_SHIPMENTS_REQUEST, error));
    }
}

export function* getInvoiceAnalyticsTopZoneSaga({ payload: { query } }) {
    try {
        const response = yield call(getInvoiceAnalyticsTopZoneApi, query);
        yield put(invoiceAnalyticsApiResponseSuccess(InvoiceAnalyticsActionTypes.GET_TOP_ZONE_REQUEST, response.data));
    } catch (error) {
        yield put(invoiceAnalyticsApiResponseError(InvoiceAnalyticsActionTypes.GET_TOP_ZONE_REQUEST, error));
    }
}

export function* getInvoiceAnalyticsTopWeightSaga({ payload: { query } }) {
    try {
        const response = yield call(getInvoiceAnalyticsTopWeightApi, query);
        yield put(invoiceAnalyticsApiResponseSuccess(InvoiceAnalyticsActionTypes.GET_TOP_WEIGHT_REQUEST, response.data));
    } catch (error) {
        yield put(invoiceAnalyticsApiResponseError(InvoiceAnalyticsActionTypes.GET_TOP_WEIGHT_REQUEST, error));
    }
}

export function* getInvoiceAnalyticsCostSaga({ payload: { query } }) {
    try {
        const response = yield call(getInvoiceAnalyticsCostApi, query);
        yield put(invoiceAnalyticsApiResponseSuccess(InvoiceAnalyticsActionTypes.GET_COST_REQUEST, response.data));
    } catch (error) {
        yield put(invoiceAnalyticsApiResponseError(InvoiceAnalyticsActionTypes.GET_COST_REQUEST, error));
    }
}

export function* getInvoiceAnalyticsAverageCostSaga({ payload: { query } }) {
    try {
        const response = yield call(getInvoiceAnalyticsAverageCostApi, query);
        yield put(invoiceAnalyticsApiResponseSuccess(InvoiceAnalyticsActionTypes.GET_AVG_COST_REQUEST, response.data));
    } catch (error) {
        yield put(invoiceAnalyticsApiResponseError(InvoiceAnalyticsActionTypes.GET_AVG_COST_REQUEST, error));
    }
}

function* getComparisonAnalyticsRecords({ payload: { query } }) {
    try {
        const getAllAnalyticsRecordsResponse = yield call(getAllAnalyticsRecordsApi, query);
        const analyticsRecords = getAllAnalyticsRecordsResponse.data.map((analyticsRecordResponse) =>
            analyticsRecordResponseToAnalyticsRecordObject(analyticsRecordResponse)
        );
        const pagination = { ...paginationHeadersResponse(getAllAnalyticsRecordsResponse.headers), ...query };
        yield put(invoiceAnalyticsApiResponseSuccess(InvoiceAnalyticsActionTypes.GET_COMPARISON_ANALYTICS_RECORDS, { analyticsRecords, pagination }));
    } catch (error) {
        yield put(addToast({ desc: error.errors[0].message, type: 'error' }));
        yield put(invoiceAnalyticsApiResponseError(InvoiceAnalyticsActionTypes.GET_COMPARISON_ANALYTICS_RECORDS, error));
    }
}

function* getComparisonRatesTotalChargeAmount({ payload: { query } }) {
    try {
        const getComparisonRatesTotalChargeAmountResponse = yield call(getComparisonRatesTotalChargeAmountApi, query);
        yield put(invoiceAnalyticsApiResponseSuccess(InvoiceAnalyticsActionTypes.GET_COMPARISON_RATES_TOTAL_CHARGE_AMOUNT, getComparisonRatesTotalChargeAmountResponse.data));
    } catch (error) {
        yield put(addToast({ desc: error.errors[0].message, type: 'error' }));
        yield put(invoiceAnalyticsApiResponseError(InvoiceAnalyticsActionTypes.GET_COMPARISON_RATES_TOTAL_CHARGE_AMOUNT, error));
    }
}

function* getComparisonRatesAvgTotalChargeAmount({ payload: { query } }) {
    try {
        const getComparisonRatesAvgTotalChargeAmountResponse = yield call(getComparisonRatesAvgTotalChargeAmountApi, query);
        yield put(invoiceAnalyticsApiResponseSuccess(InvoiceAnalyticsActionTypes.GET_COMPARISON_RATES_AVG_TOTAL_CHARGE_AMOUNT, getComparisonRatesAvgTotalChargeAmountResponse.data));
    } catch (error) {
        yield put(addToast({ desc: error.errors[0].message, type: 'error' }));
        yield put(invoiceAnalyticsApiResponseError(InvoiceAnalyticsActionTypes.GET_COMPARISON_RATES_AVG_TOTAL_CHARGE_AMOUNT, error));
    }
}

function* getTotalNoSurcharges({ payload: { query } }) {
    try {
        const getTotalNoSurchargesResponse = yield call(getTotalNoSurchargesApi, query);
        yield put(invoiceAnalyticsApiResponseSuccess(InvoiceAnalyticsActionTypes.GET_TOTAL_NO_SURCHARGES, getTotalNoSurchargesResponse.data));
    } catch (error) {
        yield put(addToast({ desc: error.errors[0].message, type: 'error' }));
        yield put(invoiceAnalyticsApiResponseError(InvoiceAnalyticsActionTypes.GET_TOTAL_NO_SURCHARGES, error));
    }
}

function* getTotalNoIntegrationSurcharges({ payload: { query } }) {
    try {
        const getTotalNoIntegrationSurchargesResponse = yield call(getTotalNoIntegrationSurchargesApi, query);
        yield put(invoiceAnalyticsApiResponseSuccess(InvoiceAnalyticsActionTypes.GET_TOTAL_NO_INTEGRATION_SURCHARGES, getTotalNoIntegrationSurchargesResponse.data));
    } catch (error) {
        yield put(addToast({ desc: error.errors[0].message, type: 'error' }));
        yield put(invoiceAnalyticsApiResponseError(InvoiceAnalyticsActionTypes.GET_TOTAL_NO_INTEGRATION_SURCHARGES, error));
    }
}

function* getTotalSurchargesCost({ payload: { query } }) {
    try {
        const getTotalSurchargesCostResponse = yield call(getTotalSurchargesCostApi, query);
        yield put(invoiceAnalyticsApiResponseSuccess(InvoiceAnalyticsActionTypes.GET_TOTAL_SURCHARGES_COST, getTotalSurchargesCostResponse.data));
    } catch (error) {
        yield put(addToast({ desc: error.errors[0].message, type: 'error' }));
        yield put(invoiceAnalyticsApiResponseError(InvoiceAnalyticsActionTypes.GET_TOTAL_SURCHARGES_COST, error));
    }
}

function* getTotalIntegrationSurchargesCost({ payload: { query } }) {
    try {
        const getTotalIntegrationSurchargesCostResponse = yield call(getTotalIntegrationSurchargesCostApi, query);
        yield put(invoiceAnalyticsApiResponseSuccess(InvoiceAnalyticsActionTypes.GET_TOTAL_INTEGRATION_SURCHARGES_COST, getTotalIntegrationSurchargesCostResponse.data));
    } catch (error) {
        yield put(addToast({ desc: error.errors[0].message, type: 'error' }));
        yield put(invoiceAnalyticsApiResponseError(InvoiceAnalyticsActionTypes.GET_TOTAL_INTEGRATION_SURCHARGES_COST, error));
    }
}

function* getSurchargeSummary({ payload: { query } }) {
    try {
        const getSurchargeSummaryResponse = yield call(getSurchargeSummaryApi, query);
        const surchargeSummary = getSurchargeSummaryResponse.data.map((surchargeSummaryResponse) =>
            surchargeSummaryResponseToSurchargeSummaryObject(surchargeSummaryResponse)
        );
        yield put(invoiceAnalyticsApiResponseSuccess(InvoiceAnalyticsActionTypes.GET_SURCHARGE_SUMMARY, surchargeSummary));
    } catch (error) {
        yield put(addToast({ desc: error.errors[0].message, type: 'error' }));
        yield put(invoiceAnalyticsApiResponseError(InvoiceAnalyticsActionTypes.GET_SURCHARGE_SUMMARY, error));
    }
}

function* getIntegrationSurchargeSummary({ payload: { query } }) {
    try {
        const getIntegrationSurchargeSummaryResponse = yield call(getIntegrationSurchargeSummaryApi, query);
        const integrationSurchargeSummary = getIntegrationSurchargeSummaryResponse.data.map((integrationSurchargeSummaryResponse) =>
            integrationSurchargeSummaryResponseToIntegrationSurchargeSummaryObject(integrationSurchargeSummaryResponse)
        );
        yield put(invoiceAnalyticsApiResponseSuccess(InvoiceAnalyticsActionTypes.GET_INTEGRATION_SURCHARGE_SUMMARY, integrationSurchargeSummary));
    } catch (error) {
        yield put(addToast({ desc: error.errors[0].message, type: 'error' }));
        yield put(invoiceAnalyticsApiResponseError(InvoiceAnalyticsActionTypes.GET_INTEGRATION_SURCHARGE_SUMMARY, error));
    }
}

function* getTotalCostByWeightAndZone({ payload: { query } }) {
    try {
        const getTotalCostByWeightAndZoneResponse = yield call(getTotalCostByWeightAndZoneApi, query);
        const totalCostByWeightAndZone = getTotalCostByWeightAndZoneResponse.data.map((totalCostByWeightAndZoneResponse) =>
            totalCostByWeightAndZoneResponseToTotalCostByWeightAndZoneObject(totalCostByWeightAndZoneResponse)
        );
        yield put(invoiceAnalyticsApiResponseSuccess(InvoiceAnalyticsActionTypes.GET_TOTAL_COST_BY_WEIGHT_AND_ZONE, totalCostByWeightAndZone));
    } catch (error) {
        console.log('error', error);
        yield put(addToast({ desc: error.errors[0].message, type: 'error' }));
        yield put(invoiceAnalyticsApiResponseError(InvoiceAnalyticsActionTypes.GET_TOTAL_COST_BY_WEIGHT_AND_ZONE, error));
    }
}

function* getCountByWeightAndZone({ payload: { query } }) {
    try {
        const getCountByWeightAndZoneResponse = yield call(getCountByWeightAndZoneApi, query);
        const totalCountByWeightAndZone = getCountByWeightAndZoneResponse.data.map((totalCountByWeightAndZoneResponse) =>
            totalCountByWeightAndZoneResponseToTotalCountByWeightAndZoneObject(totalCountByWeightAndZoneResponse)
    );
        yield put(invoiceAnalyticsApiResponseSuccess(InvoiceAnalyticsActionTypes.GET_COUNT_BY_WEIGHT_AND_ZONE, totalCountByWeightAndZone));
    } catch (error) {
        yield put(addToast({ desc: error.errors[0].message, type: 'error' }));
        yield put(invoiceAnalyticsApiResponseError(InvoiceAnalyticsActionTypes.GET_COUNT_BY_WEIGHT_AND_ZONE, error));
    }
}

export function* watchGetInvoiceAnalyticsNoShipments(): any {
    yield takeLatest(InvoiceAnalyticsActionTypes.GET_NO_SHIPMENTS_REQUEST, getInvoiceAnalyticsNoShipments);
}

export function* watchGetInvoiceAnalyticsTopZone(): any {
    yield takeLatest(InvoiceAnalyticsActionTypes.GET_TOP_ZONE_REQUEST, getInvoiceAnalyticsTopZoneSaga);
}

export function* watchGetInvoiceAnalyticsTopWeight(): any {
    yield takeLatest(InvoiceAnalyticsActionTypes.GET_TOP_WEIGHT_REQUEST, getInvoiceAnalyticsTopWeightSaga);
}

export function* watchGetInvoiceAnalyticsCost(): any {
    yield takeLatest(InvoiceAnalyticsActionTypes.GET_COST_REQUEST, getInvoiceAnalyticsCostSaga);
}

export function* watchGetInvoiceAnalyticsAverageCost(): any {
    yield takeLatest(InvoiceAnalyticsActionTypes.GET_AVG_COST_REQUEST, getInvoiceAnalyticsAverageCostSaga);
}

export function* watchGetComparisonAnalyticsRecords(): any {
    yield takeLatest(InvoiceAnalyticsActionTypes.GET_COMPARISON_ANALYTICS_RECORDS, getComparisonAnalyticsRecords);
}

export function* watchGetComparisonRatesTotalChargeAmount(): any {
    yield takeLatest(InvoiceAnalyticsActionTypes.GET_COMPARISON_RATES_TOTAL_CHARGE_AMOUNT, getComparisonRatesTotalChargeAmount);
}

export function* watchGetComparisonRatesAvgTotalChargeAmount(): any {
    yield takeLatest(InvoiceAnalyticsActionTypes.GET_COMPARISON_RATES_AVG_TOTAL_CHARGE_AMOUNT, getComparisonRatesAvgTotalChargeAmount);
}

export function* watchGetTotalNoSurcharges(): any {
    yield takeLatest(InvoiceAnalyticsActionTypes.GET_TOTAL_NO_SURCHARGES, getTotalNoSurcharges);
}

export function* watchGetTotalNoIntegrationSurcharges(): any {
    yield takeLatest(InvoiceAnalyticsActionTypes.GET_TOTAL_NO_INTEGRATION_SURCHARGES, getTotalNoIntegrationSurcharges);
}

export function* watchGetTotalSurchargesCost(): any {
    yield takeLatest(InvoiceAnalyticsActionTypes.GET_TOTAL_SURCHARGES_COST, getTotalSurchargesCost);
}

export function* watchGetTotalIntegrationSurchargesCost(): any {
    yield takeLatest(InvoiceAnalyticsActionTypes.GET_TOTAL_INTEGRATION_SURCHARGES_COST, getTotalIntegrationSurchargesCost);
}

export function* watchGetSurchargeSummary(): any {
    yield takeLatest(InvoiceAnalyticsActionTypes.GET_SURCHARGE_SUMMARY, getSurchargeSummary);
}

export function* watchGetIntegrationSurchargeSummary(): any {
    yield takeLatest(InvoiceAnalyticsActionTypes.GET_INTEGRATION_SURCHARGE_SUMMARY, getIntegrationSurchargeSummary);
}

export function* watchGetTotalCostByWeightAndZone(): any {
    yield takeLatest(InvoiceAnalyticsActionTypes.GET_TOTAL_COST_BY_WEIGHT_AND_ZONE, getTotalCostByWeightAndZone);
}

export function* watchGetCountByWeightAndZone(): any {
    yield takeLatest(InvoiceAnalyticsActionTypes.GET_COUNT_BY_WEIGHT_AND_ZONE, getCountByWeightAndZone);
}

function* invoiceAnalyticsSaga() {
    yield all([
        fork(watchGetInvoiceAnalyticsNoShipments),
        fork(watchGetInvoiceAnalyticsTopZone),
        fork(watchGetInvoiceAnalyticsTopWeight),
        fork(watchGetInvoiceAnalyticsCost),
        fork(watchGetInvoiceAnalyticsAverageCost),
        fork(watchGetComparisonAnalyticsRecords),
        fork(watchGetComparisonRatesTotalChargeAmount),
        fork(watchGetComparisonRatesAvgTotalChargeAmount),
        fork(watchGetTotalNoSurcharges),
        fork(watchGetTotalNoIntegrationSurcharges),
        fork(watchGetTotalSurchargesCost),
        fork(watchGetTotalIntegrationSurchargesCost),
        fork(watchGetSurchargeSummary),
        fork(watchGetIntegrationSurchargeSummary),
        fork(watchGetTotalCostByWeightAndZone),
        fork(watchGetCountByWeightAndZone),
    ]);
}

export default invoiceAnalyticsSaga;
